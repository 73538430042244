<template>
  <v-combobox v-model="category" hide-details :items="reportCategoryList" :append-icon="null" item-text="name" item-value="id" outlined dense clearable />
</template>

<script>
import { defineComponent, reactive, watch, toRefs, onBeforeMount } from '@vue/composition-api'

export default defineComponent({
  props: { value: Object },

  setup(props, { root, emit }) {
    const state = reactive({
      reportCategoryList: [],
      category: null,
    })

    const getListReportCategory = async () => {
      state.reportCategoryList = await root.$store.dispatch('report/getListReportCategory')
    }

    onBeforeMount(async () => {
      getListReportCategory()
      state.category = props.value
    })

    watch(
      () => props.value,
      () => {
        state.category = props.value
      },
    )

    watch(
      () => state.category,
      () => {
        emit('input', state.category)
      },
    )

    return {
      ...toRefs(state),
    }
  },
})
</script>
