<template>
  <v-container fluid>
    <v-card-title>
      {{ $route.name }}
      <v-spacer />
      <v-btn outlined to="/report/create">
        <v-icon left>mdi-plus</v-icon>
        등록
      </v-btn>
    </v-card-title>

    <v-card outlined class="pa-3 mb-3">
      <v-row>
        <v-col cols="1" class="my-auto text-center">작성일시</v-col>
        <v-col cols="4" class="my-auto">
          <v-btn small depressed :color="req.createdAt !== 0 ? null : '#ffd600'" :text="req.createdAt !== 0" @click="setCreatedAt(0)">
            오늘
          </v-btn>
          <v-btn class="mx-1" small depressed :color="req.createdAt !== 1 ? null : '#ffd600'" :text="req.createdAt !== 1" @click="setCreatedAt(1)">
            1주
          </v-btn>
          <v-btn small depressed :color="req.createdAt !== 2 ? null : '#ffd600'" :text="req.createdAt !== 2" @click="setCreatedAt(2)">
            1개월
          </v-btn>
          <v-btn class="mx-1" small depressed :color="req.createdAt !== 3 ? null : '#ffd600'" :text="req.createdAt !== 3" @click="setCreatedAt(3)">
            6개월
          </v-btn>
          <v-btn small depressed :color="req.createdAt !== 4 ? null : '#ffd600'" :text="req.createdAt !== 4" @click="setCreatedAt(4)">
            1년
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-menu v-model="base.fromCreatedAtMenu" :close-on-content-click="false" :nudge-right="40" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="req.fromCreatedAt"
                outlined
                dense
                hide-details
                label="시작일"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker v-model="req.fromCreatedAt" no-title scrollable locale="ko-kr" :max="req.toCreatedAt || new Date().toISOString().substr(0, 10)" />
          </v-menu>
        </v-col>
        <span class="mt-5">~</span>
        <v-col cols="2">
          <v-menu v-model="base.toCreatedAtMenu" :close-on-content-click="false" :nudge-right="40" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="req.toCreatedAt" outlined dense hide-details label="종료일" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" />
            </template>
            <v-date-picker v-model="req.toCreatedAt" no-title scrollable locale="ko-kr" :max="new Date().toISOString().substr(0, 10)" />
          </v-menu>
        </v-col>
      </v-row>
      <v-row class="pb-1">
        <v-col cols="1" class="my-auto text-center">발행일시</v-col>
        <v-col cols="4" class="my-auto">
          <v-btn small depressed :color="req.publishedAt !== 0 ? null : '#ffd600'" :text="req.publishedAt !== 0" @click="setPublishedAt(0)">
            오늘
          </v-btn>
          <v-btn class="mx-1" small depressed :color="req.publishedAt !== 1 ? null : '#ffd600'" :text="req.publishedAt !== 1" @click="setPublishedAt(1)">
            1주
          </v-btn>
          <v-btn small depressed :color="req.publishedAt !== 2 ? null : '#ffd600'" :text="req.publishedAt !== 2" @click="setPublishedAt(2)">
            1개월
          </v-btn>
          <v-btn class="mx-1" small depressed :color="req.publishedAt !== 3 ? null : '#ffd600'" :text="req.publishedAt !== 3" @click="setPublishedAt(3)">
            6개월
          </v-btn>
          <v-btn small depressed :color="req.publishedAt !== 4 ? null : '#ffd600'" :text="req.publishedAt !== 4" @click="setPublishedAt(4)">
            1년
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-menu v-model="base.fromPublishedAtMenu" :close-on-content-click="false" :nudge-right="40" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="req.fromPublishedAt"
                outlined
                dense
                hide-details
                label="시작일"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="req.fromPublishedAt"
              no-title
              scrollable
              locale="ko-kr"
              :max="req.toPublishedAt || new Date().toISOString().substr(0, 10)"
            />
          </v-menu>
        </v-col>
        <span class="mt-5">~</span>
        <v-col cols="2">
          <v-menu v-model="base.toPublishedAtMenu" :close-on-content-click="false" :nudge-right="40" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="req.toPublishedAt"
                outlined
                dense
                hide-details
                label="종료일"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker v-model="req.toPublishedAt" no-title scrollable locale="ko-kr" :max="new Date().toISOString().substr(0, 10)" />
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="1" class="my-auto text-center">발행상태</v-col>
        <v-col>
          <v-btn
            small
            depressed
            :color="req.toPublishedAt ? '#ffd600' : ''"
            :text="!req.toPublishedAt"
            @click="
              req.toPublishedAt = new Date().toISOString().substr(0, 10)
              req.fromPublishedAt = null
              req.publishedAt = null
              req.nullPublishedAt = false
            "
          >
            발행중
          </v-btn>
          <v-btn
            class="mx-1"
            small
            depressed
            :color="req.fromPublishedAt ? '#ffd600' : ''"
            :text="!req.fromPublishedAt"
            @click="
              req.fromPublishedAt = new Date().toISOString().substr(0, 10)
              req.toPublishedAt = null
              req.publishedAt = null
              req.nullPublishedAt = false
            "
          >
            발행예정
          </v-btn>
          <v-btn
            small
            depressed
            :color="req.nullPublishedAt ? '#ffd600' : ''"
            :text="!req.nullPublishedAt"
            @click="
              req.fromPublishedAt = null
              req.toPublishedAt = null
              req.publishedAt = null
              req.nullPublishedAt = true
            "
          >
            보관중
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="1" class="my-auto text-center">작성멘토</v-col>
        <v-col cols="3">
          <MentorInput v-model="req.mentor" />
        </v-col>
        <v-col cols="1" class="my-auto text-center">카테고리</v-col>
        <v-col cols="3">
          <CategoryInput v-model="req.columnCategoryId" />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="my-auto text-center" cols="1">라벨</v-col>
        <v-col v-for="(e, i) in base.select" :key="i" cols="2">
          <v-select
            v-model="req[e.model]"
            dense
            hide-details
            :prepend-icon="`mdi-numeric-${i + 1}-box`"
            :append-icon="null"
            :items="res[e.model]"
            :disabled="e.disabled ? !req[e.disabled] : false"
            outlined
            clearable
            color="#ffd600"
            @change="changeDepth(i)"
          />
        </v-col>
        <v-chip label color="#888" text-color="#FFF" class="mt-5 ml-5" small>
          투명라벨
        </v-chip>
        <v-col cols="3">
          <v-combobox
            v-model="req.invisibleStamp"
            dense
            hide-details
            multiple
            :append-icon="null"
            :items="listInvisibleStamp"
            outlined
            clearable
            color="#ffd600"
          />
        </v-col>
      </v-row>
      <v-card-actions class="mt-3">
        <v-spacer />
        <v-btn depressed color="#ffd600" class="mr-3" light @click="getListReport">
          조회
        </v-btn>
        <v-btn depressed @click="resetReq">설정초기화</v-btn>
      </v-card-actions>
    </v-card>

    <v-card outlined>
      <v-data-table
        v-model="req.selected"
        :headers="base.headers"
        :items="res.report"
        :items-per-page="req.offset"
        show-select
        hide-default-footer
        @click:row="
          (row) => {
            $router.push(`${$route.path}/${row.id}`)
          }
        "
      >
        <template v-slot:item.status="{ item }">
          {{ new Date(item.publishedAt) > new Date() ? '발행예정' : item.publishedAt ? '발행중' : '보관중' }}
        </template>
        <template v-slot:item.stamp="{ item }">
          <QuestionTag :tags="item.stamp" />
        </template>
        <template v-slot:item.createdAt="{ item }">
          {{ day(item.createdAt) }}
        </template>
        <template v-slot:item.publishedAt="{ item }">
          {{ day(item.publishedAt) }}
        </template>
        <template v-slot:footer>
          <v-divider />
          <v-container fluid class="row" no-gutters>
            <v-btn icon class="mr-3" @click="deleteReport">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
            <v-col cols="1">
              <v-select v-model="req.offset" color="#ffd600" :items="[5, 10, 15, 25, 50, 100]" outlined dense hide-details />
            </v-col>
            <v-spacer />
            <v-col>
              <v-pagination v-model="req.page" total-visible="13" :length="res.pageNumber" circle color="#ffd600" @input="getListReport" />
            </v-col>
          </v-container>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, toRefs, watch } from '@vue/composition-api'
import { day } from '@/helper'
import QuestionTag from '@/components/QuestionTag.vue'
import MentorInput from '@/components/input/Mentor.vue'
import CategoryInput from '@/components/input/Category.vue'

export default defineComponent({
  components: { QuestionTag, MentorInput, CategoryInput },

  setup(props, { root }) {
    const state = reactive({
      base: {
        fromPublishedAtMenu: false,
        toPublishedAtMenu: false,
        fromCreatedAtAtMenu: false,
        toCreatedAtAtMenu: false,
        headers: [
          { text: 'ID', value: 'id' },
          { text: '발행상태', value: 'status' },
          { text: '라벨', value: 'stamp' },
          { text: '제목', value: 'title' },
          { text: '작성일시', value: 'createdAt' },
          { text: '발행일시', value: 'publishedAt' },
          { text: '작성멘토', value: 'author.name' },
          { text: '조회수', value: 'views' },
          { text: '좋아요수', value: 'likeCount' },
          { text: '북마크수', value: 'bookmarkCount' },
          { text: '구매수', value: 'soldCount' },
        ],
        select: [
          { model: 'firstDepth', disabled: null },
          { model: 'secondDepth', disabled: 'firstDepth' },
          { model: 'thirdDepth', disabled: 'secondDepth' },
        ],
      },
      res: {
        firstDepth: [],
        secondDepth: [],
        thirdDepth: [],
        report: [],
        pageNumber: null,
      },
      listInvisibleStamp: [],
      req: {
        firstDepth: null,
        secondDepth: null,
        thirdDepth: null,
        createdAt: null,
        publishedAt: null,
        toCreatedAt: null,
        fromCreatedAt: null,
        toPublishedAt: null,
        fromPublishedAt: null,
        nullPublishedAt: false,
        invisibleStamp: [],
        mentor: null,
        mentorId: null,
        columnCategoryId: null,
        selected: [],
        offset: 15,
        page: 1,
      },
    })

    const getListReport = async () => {
      try {
        sessionStorage.setItem('report/req', JSON.stringify(state.req))
        state.req.selected = []

        const listReport = await root.$store.dispatch('report/getListReport', {
          sort: {
            what: 'createdAt',
            sort: 'desc',
          },
          offset: state.req.offset,
          pageNumber: state.req.page,
          firstDepth: state.req.firstDepth ?? undefined,
          secondDepth: state.req.secondDepth ?? undefined,
          thirdDepth: state.req.thirdDepth ?? undefined,
          fromCreatedAt: state.req.fromCreatedAt ? new Date(state.req.fromCreatedAt) : undefined,
          toCreatedAt: state.req.toCreatedAt ? new Date(state.req.toCreatedAt) : undefined,
          fromPublishedAt: state.req.fromPublishedAt ? new Date(state.req.fromPublishedAt) : state.req.nullPublishedAt ? null : undefined,
          toPublishedAt: state.req.toPublishedAt ? new Date(state.req.toPublishedAt) : state.req.nullPublishedAt ? null : undefined,
          mentorId: state.req.mentor ? Number(state.req.mentor.id) : undefined,
          columnCategoryId: state.req.columnCategoryId?.id ?? undefined,
          invisibleNameList: state.req.invisibleStamp.length ? state.req.invisibleStamp.map((e) => e.value) : undefined,
        })

        state.res.report = listReport.data
        state.res.pageNumber = Math.ceil(listReport.totalCount / state.req.offset)
      } catch (err) {
        console.error(err)
        alert(err)
      }
    }

    const resetReq = () => {
      state.req.createdAt = null
      state.req.publishedAt = null
      state.req.firstDepth = null
      state.req.secondDepth = null
      state.req.thirdDepth = null
      state.req.toCreatedAt = null
      state.req.fromCreatedAt = null
      state.req.toPublishedAt = null
      state.req.fromPublishedAt = null
      state.req.nullPublishedAt = false
      state.req.invisibleStamp = []
      state.req.mentor = null
      state.req.mentorId = null
      state.req.columnCategoryId = null
    }

    const setCreatedAt = (number) => {
      const date = new Date()
      state.req.toCreatedAt = date.toISOString().substr(0, 10)
      if (number === 1) date.setDate(date.getDate() - 7)
      if (number === 2) date.setMonth(date.getMonth() - 1)
      if (number === 3) date.setMonth(date.getMonth() - 6)
      if (number === 4) date.setFullYear(date.getFullYear() - 1)
      state.req.fromCreatedAt = date.toISOString().substr(0, 10)
      state.req.createdAt = number
    }

    const setPublishedAt = (number) => {
      const date = new Date()
      state.req.toPublishedAt = date.toISOString().substr(0, 10)
      if (number === 1) date.setDate(date.getDate() - 7)
      if (number === 2) date.setMonth(date.getMonth() - 1)
      if (number === 3) date.setMonth(date.getMonth() - 6)
      if (number === 4) date.setFullYear(date.getFullYear() - 1)
      state.req.fromPublishedAt = date.toISOString().substr(0, 10)
      state.req.publishedAt = number
      state.req.nullPublishedAt = false
    }

    const selectDepth = async (firstDepth, secondDepth) => {
      const newStamp = await root.$store.dispatch('stamp/getNewStamp', {
        firstDepth: firstDepth ?? undefined,
        secondDepth: secondDepth ?? undefined,
      })

      if (state.req.secondDepth) state.res.thirdDepth = []
      else if (state.req.firstDepth) state.res.secondDepth = []
      newStamp.map((e) => {
        if (e.firstDepth !== null) state.res.firstDepth.push(e.firstDepth)
        else if (e.secondDepth !== null) state.res.secondDepth.push(e.secondDepth)
        else if (e.thirdDepth !== null) state.res.thirdDepth.push(e.thirdDepth)
      })
    }

    const deleteReport = async () => {
      if (!confirm(`${state.req.selected.length}개의 레포트를 삭제합니다. 레포트 삭제 후 되돌릴 수 없습니다.`)) return

      try {
        for (const e of state.req.selected) {
          await root.$store.dispatch('report/deleteReport', {
            id: Number(e.id),
          })
        }
        alert(`레포트 ${state.req.selected.length}개가 정상적으로 삭제되었습니다.`)
        state.req.selected = []
        getListReport()
      } catch (err) {
        console.log(err)
        alert(err)
      }
    }

    watch(
      () => root.$route.path,
      () => {
        state.req = {
          firstDepth: null,
          secondDepth: null,
          thirdDepth: null,
          createdAt: null,
          publishedAt: null,
          toCreatedAt: null,
          fromCreatedAt: null,
          toPublishedAt: null,
          fromPublishedAt: null,
          mentor: null,
          mentorId: null,
          selected: [],
          offset: 15,
          page: 1,
        }
        getListReport()
      },
    )

    const changeDepth = async (number) => {
      if (number === 1) {
        state.req.thirdDepth = null
        if (state.req.secondDepth) selectDepth(state.req.firstDepth, state.req.secondDepth)
      } else if (number === 0) {
        state.req.secondDepth = null
        state.req.thirdDepth = null
        if (state.req.firstDepth) selectDepth(state.req.firstDepth)
      }
    }

    watch(
      () => state.req.offset,
      () => {
        getListReport()
        state.req.page = 1
      },
    )

    onBeforeMount(async () => {
      if (sessionStorage.getItem('report/req')) state.req = JSON.parse(sessionStorage.getItem('report/req'))

      selectDepth()
      if (state.req.firstDepth) selectDepth(state.req.firstDepth)
      if (state.req.secondDepth) selectDepth(state.req.firstDepth, state.req.secondDepth)

      const listInvisibleStamp = await root.$store.dispatch('stamp/getListInvisibleStamp')
      state.listInvisibleStamp = listInvisibleStamp.map((e) => ({
        text: e.name,
        value: e.name,
      }))

      getListReport()
    })

    return {
      ...toRefs(state),
      getListReport,
      resetReq,
      setCreatedAt,
      setPublishedAt,
      deleteReport,
      changeDepth,
      day,
    }
  },
})
</script>
